/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Inventory = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <path d="m2.75 24c-.414 0-.75-.336-.75-.75v-22.5c0-.414.336-.75.75-.75s.75.336.75.75v22.5c0 .414-.336.75-.75.75z" />
    <path d="m4.25 24h-3.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h3.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
    <path d="m21.25 24c-.414 0-.75-.336-.75-.75v-22.5c0-.414.336-.75.75-.75s.75.336.75.75v22.5c0 .414-.336.75-.75.75z" />
    <path d="m23.25 24h-3.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h3.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
    <path d="m20.5 19h-17c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h17c.414 0 .75.336.75.75s-.336.75-.75.75z" />
    <path d="m20.5 10h-17c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h17c.414 0 .75.336.75.75s-.336.75-.75.75z" />
    <path d="m10.25 12h-4.5c-.414 0-.75.336-.75.75v5.5c0 .414.336.75.75.75h4.5c.414 0 .75-.336.75-.75v-5.5c0-.414-.336-.75-.75-.75z" />
    <path d="m18.25 12h-4.5c-.414 0-.75.336-.75.75v5.5c0 .414.336.75.75.75h4.5c.414 0 .75-.336.75-.75v-5.5c0-.414-.336-.75-.75-.75z" />
    <path d="m10.25 3h-4.5c-.414 0-.75.336-.75.75v5.5c0 .414.336.75.75.75h4.5c.414 0 .75-.336.75-.75v-5.5c0-.414-.336-.75-.75-.75z" />
    <path d="m18.25 3h-4.5c-.414 0-.75.336-.75.75v5.5c0 .414.336.75.75.75h4.5c.414 0 .75-.336.75-.75v-5.5c0-.414-.336-.75-.75-.75z" />
  </SvgIcon>
);

export default Inventory;
