import {useEffect, useRef} from 'react';
import {batch, useDispatch, useSelector} from 'react-redux';
import {Auth, Firestore} from 'firebaseConfig';
import {inventoryOnsiteActions} from 'state/inventory/slices/onsite';
const queryOptions = {includeMetadataChanges: true};

const useInventoryOnSite = () => {
  const onsite = useSelector(state => state.inventory.onsite);
  const dispatch = useDispatch();
  const duplicateRender = useRef(false);

  useEffect(() => {
    // Prevent duplicate renders caused by React 18 strict mode
    if (duplicateRender.current) return;
    duplicateRender.current = true;

    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (onsite.touched) return;
    dispatch(inventoryOnsiteActions.setTouched(true));

    let unsubscribe = null;
    // Create functions to get state or forget state depending on user's auth status
    const getState = () => {
      const query = Firestore.query(
        Firestore.collection('Inventory-Containers'),
        Firestore.where('OnSite', '==', true),
      );
      const snapData = snap => {
        // Never use a snapshot fromCache or that hasPendingWrites (Data may be corrupt); Use pure data from server only;
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const data = {all: []};

        // If snap is empty and redux state is pending, then set pending to false to show first snap performed
        if (snap.empty && onsite.pending) {
          batch(() => {
            dispatch(inventoryOnsiteActions.setData(data));
            dispatch(inventoryOnsiteActions.setPending(false));
          });
          return;
        }

        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            DocId: doc.id,
            AccumulationDate: docData.AccumulationDate.toMillis(),
            System: {
              ...docData.System,
              EnteredOn: docData.System.EnteredOn.toMillis(),
              RemovedOn: docData?.System?.RemovedOn ? docData.System.RemovedOn.toMillis() : null,
            },
          };
          data.all.push(cleanDoc);
        });

        // Batch redux dispatch to push setData and SetPending simultaneously
        batch(() => {
          dispatch(inventoryOnsiteActions.setData(data));
          dispatch(inventoryOnsiteActions.setPending(false));
        });
      };
      const snapError = error => {
        alert(error.message);
        console.log('hooks/state/useOnSiteInventory Error: ', error.message, {error});
      };
      unsubscribe = Firestore.onSnapshot(query, queryOptions, snapData, snapError);
    };
    const forgetState = () => {
      dispatch(inventoryOnsiteActions.forgetState());
      if (unsubscribe) unsubscribe();
    };

    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      if (user) getState();
      else forgetState();
    });
  }, [onsite, dispatch]);

  return onsite;
};

export default useInventoryOnSite;
