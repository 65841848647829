import {initializeApp} from 'firebase/app';
import {initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check';
import {getAuth, onAuthStateChanged, onIdTokenChanged} from 'firebase/auth';
import {signInWithCustomToken, signOut, updateEmail, updateProfile} from 'firebase/auth';
import {getFirestore, addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, getCountFromServer, getDoc, getDocs} from 'firebase/firestore';
import {limit, onSnapshot, orderBy, query, runTransaction, serverTimestamp, setDoc, updateDoc, where, writeBatch, collectionGroup} from 'firebase/firestore';
import {getFunctions, connectFunctionsEmulator, httpsCallable, httpsCallableFromURL} from 'firebase/functions';
import {getRemoteConfig, ensureInitialized, fetchAndActivate, getValue} from 'firebase/remote-config';
import {getStorage, deleteObject, getDownloadURL, getMetadata, updateMetadata, listAll, ref, uploadBytesResumable, uploadBytes} from 'firebase/storage';

const isProductionEnv = process.env.NODE_ENV === 'production';
const FireProd = initializeApp({
  apiKey: 'AIzaSyDqz6pE11VpzUyM9BCVg4W96D9PwWhZfqs',
  authDomain: 'kbi-catalyst-ec5cb.firebaseapp.com',
  projectId: 'kbi-catalyst-ec5cb',
  storageBucket: 'kbi-catalyst-ec5cb.appspot.com',
  messagingSenderId: '947994172107',
  appId: '1:947994172107:web:6a1ebeff24769feead994e',
  measurementId: 'G-CEC4QR3JDB',
}, 'FireProd');
const FireDev = initializeApp({
  apiKey: 'AIzaSyBIbzpYLNnZMFdMNjVEKkCQ2Nq4XNqbAd0',
  authDomain: 'kbi-catalyst-dev.firebaseapp.com',
  projectId: 'kbi-catalyst-dev',
  storageBucket: 'kbi-catalyst-dev.appspot.com',
  messagingSenderId: '645821530137',
  appId: '1:645821530137:web:a9a34183b929d6efaf2a88',
  measurementId: 'G-8RTPGLJJBP',
}, 'FireDev');

// Initialize Firebase App Check (Only for Production Environment)
if (isProductionEnv) {
  initializeAppCheck(FireProd, {
    provider: new ReCaptchaV3Provider('6LeWI2EgAAAAALDp0nvSRYOIfyI59IFl3X5ly60x'),
    isTokenAutoRefreshEnabled: true,
  });
}

// Initialize Firebase Authentication with conditional override for development environment
const AuthInit = getAuth(isProductionEnv ? FireProd : FireDev);
const Auth = {
  currentUser: () => AuthInit.currentUser,
  onAuthStateChanged: cb => onAuthStateChanged(AuthInit, cb),
  onIdTokenChanged: cb => onIdTokenChanged(AuthInit, cb),
  signInWithCustomToken: customToken => signInWithCustomToken(AuthInit, customToken),
  signOut: () => signOut(AuthInit),
  updateEmail: email => updateEmail(AuthInit.currentUser, email),
  updateProfile: profile => updateProfile(AuthInit.currentUser, profile),
};

// Initialize Firebase Firestore with conditional override for development environment
const FirestoreInit = getFirestore(isProductionEnv ? FireProd : FireDev);
const Firestore = {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection: collectionPath => collection(FirestoreInit, collectionPath),
  deleteDoc,
  doc: param => {
    // When param type is a Firestore collection, passing FirestoreInit will cause system crash (Init already passed to collection);
    if (param?.type === 'collection') return doc(param);
    // Otherewise pass FirestoreInit to properly initialize doc module;
    return doc(FirestoreInit, param);
  },
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  runTransaction: transaction => runTransaction(FirestoreInit, transaction),
  setDoc,
  serverTimestamp,
  updateDoc,
  where,
  collectionGroup: (collection) => collectionGroup(FirestoreInit, collection),
  writeBatch: () => writeBatch(FirestoreInit),
};

// Initialize Firebase Functions with conditional override for development environment
const useFunctionEmulator = false;
const FunctionsInit = getFunctions((() => {
  // Always init FireProd when using production environment
  if (isProductionEnv) return FireProd;
  // Init FireProd when using development environment and Firebase Function emulator
  else if (!isProductionEnv && useFunctionEmulator) return FireProd;
  // Init FireDev when using development environment but not Firebase Function emulator
  else if (!isProductionEnv && !useFunctionEmulator) return FireDev;
})());
if (!isProductionEnv && useFunctionEmulator) {
  console.log('Firebase looking for Cloud Functions emulator. To prevent errors, ensure emulator is running or set useFunctionEmulator false.');
  connectFunctionsEmulator(FunctionsInit, 'localhost', 5001);
}
const Functions = {
  httpsCallable: functionName => httpsCallable(FunctionsInit, functionName),
  httpsCallableFromURL: functionUrl => httpsCallableFromURL(FunctionsInit, functionUrl),
};

// Initialize RemoteConfig with conditional override for development environment
const RemoteConfigInit = getRemoteConfig(isProductionEnv ? FireProd : FireDev);
RemoteConfigInit.settings.minimumFetchIntervalMillis = isProductionEnv ? 3600000 : 15000; // One hour : 15 seconds
const RemoteConfig = {
  ensureInitialized: () => ensureInitialized(RemoteConfigInit),
  fetchAndActivate: () => fetchAndActivate(RemoteConfigInit),
  getValue: key => getValue(RemoteConfigInit, key),
};

// Initialize Firebase Storage with conditional override for development environment
const StorageInit = getStorage(isProductionEnv ? FireProd : FireDev);
const Storage = {
  deleteObject,
  getDownloadURL,
  getMetadata,
  listAll,
  ref: refPath => ref(StorageInit, refPath),
  updateMetadata,
  uploadBytesResumable,
  uploadBytes,
};

export {Auth, Firestore, Functions, RemoteConfig, Storage, isProductionEnv};
