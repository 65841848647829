import {useDispatch, useSelector} from 'react-redux';
import {drawerActions} from 'state/ui/slices/drawer';

const useUiDrawer = () => {
  const dispatch = useDispatch();
  const drawer = useSelector(state => state.ui.drawer);

  const setDrawer = payload => dispatch(drawerActions.setDrawer(payload));

  const setDrawerOpen = () => dispatch(drawerActions.setDrawerOpen());

  const setDrawerClose = () => dispatch(drawerActions.setDrawerClose());

  const setDrawerToggle = () => dispatch(drawerActions.setDrawerToggle());

  return {drawerOpen: drawer.open, setDrawer, setDrawerOpen, setDrawerClose, setDrawerToggle};
};

export default useUiDrawer;
