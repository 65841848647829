/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Payment = (props) => (
  <SvgIcon viewBox='0 0 512 512' {...props}>
    <g>
      <g>
        <path d='M46,302H15c-8.291,0-15,6.709-15,15v180c0,8.291,6.709,15,15,15h31c24.814,0,45-20.186,45-45V347 C91,322.186,70.814,302,46,302z' />
      </g>
    </g>
    <g>
      <g>
        <path d='M347,0c-90.901,0-165,74.099-165,165c0,39.6,14.099,75.899,37.5,104.399c18.9,5.4,36.599,15,51.599,28.201c2.821,2.116,4.414,2.091,7.5,2.4H332c20.7,0,39,10.199,49.501,26.4C455.9,310.499,512,244.2,512,165C512,74.099,437.901,0,347,0z M362.3,237.299H362V255c0,8.399-6.599,15-15,15s-15-6.601-15-15v-17.701c-9.9-3.3-19.2-9.6-27.299-19.499 c-5.401-6.301-4.5-15.601,1.8-21c6.299-5.4,15.899-4.5,20.999,1.8c8.101,9.6,17.1,13.2,24.6,10.499c6-2.1,9.9-7.8,9.9-14.099 c0-8.401-6.599-15-15-15c-24.9,0-45-20.101-45-45c0-18.001,10.8-34.501,27.6-41.4c0.601-0.3,1.5-0.3,2.4-0.601V75c0-8.401,6.599-15,15-15s15,6.599,15,15v17.999c7.8,2.701,15.3,6.601,22.2,13.2c6,5.7,6,15.3,0.3,21.301s-15.3,6-21.301,0.3 c-7.2-6.901-15.298-9.6-21.899-6.601C335.6,123.6,332,129,332,135c0,8.399,6.599,15,15,15c24.9,0,45,20.1,45,45C392,213.9,379.999,230.7,362.3,237.299z' />
      </g>
    </g>
    <g>
      <g>
        <path d='M507.5,331.099c-1.801-2.999-4.199-5.4-6.9-7.5c-11.045-9.663-29.654-8.749-40.499,3.001L392,405.2l-2.1,2.398c-8.399,9.3-20.4,14.401-33,14.401H239.5c-8.401,0-15-6.601-15-15c0-8.401,6.599-15,15-15H332c16.5,0,30-13.5,30-30v-0.3c-0.3-16.5-13.5-29.7-30-29.7h-55.3c-8.996,0-18.637-3.303-26.4-9.9c-36.599-32.1-90-34.201-129.3-6.899v184.499C150.7,507.8,181.3,512,212.199,512H347c32.999,0,64.2-15.601,84-42.001l72.001-96C513.506,360.21,514.298,341.3,507.5,331.099z' />
      </g>
    </g>
  </SvgIcon>
);

export default Payment;
