import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Auth, Firestore} from 'firebaseConfig';
import {individualsActions} from 'state/suppliers/slices/individuals';
const queryOptions = {includeMetadataChanges: true};

const useSupplierIndividuals = () => {
  const individuals = useSelector(state => state.suppliers.individuals);
  const dispatch = useDispatch();
  const duplicateRender = useRef(false);

  useEffect(() => {
    // Prevent duplicate renders caused by React 18 strict mode
    if (duplicateRender.current) return;
    duplicateRender.current = true;

    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (individuals.touched) return;
    dispatch(individualsActions.setTouched(true));

    let unsubscribe = null;
    // Create functions to get state or forget state depending on user's auth status
    const getState = () => {
      const snapData = snap => {
        // Never use a snapshot fromCache or that hasPendingWrites (Data may be corrupt); Use pure data from server only;
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        // If collection is empty and redux state is pending, then set pending to false to show first snap performed
        if (snap.size === 0 && individuals.pending) dispatch(individualsActions.setPending(false));
        // If 1 or more doc change in snap, then loop through changes and dispatch to redux state
        else if (snap.size >= 1) {
          const data = {all: [], active: []};
          snap.forEach(doc => {
            const docData = doc.data();
            const cleanDoc = {
              ...docData,
              DocId: doc.id,
              Catapp: (() => {
                if (!docData?.Catapp) return null;
                const {Metadata} = docData.Catapp || {};
                return {
                  ...docData.Catapp,
                  Metadata: {
                    ...Metadata,
                    CreationTime: Metadata?.CreationTime?.toMillis ? Metadata.CreationTime.toMillis() : null,
                    LastSignInTime: Metadata?.LastSignInTime?.toMillis ? Metadata.LastSignInTime.toMillis() : null,
                  },
                };
              })(),
              LastSale: docData?.LastSale ? docData.LastSale.toMillis() : null,
              System: {
                ...docData.System,
                EnteredOn: docData?.System?.EnteredOn ? docData.System.EnteredOn.toMillis() : null,
              },
            };
            data.all.push(cleanDoc);
            if (cleanDoc?.Active) data.active.push(cleanDoc);
          });

          dispatch(individualsActions.setData(data));
          dispatch(individualsActions.setPending(false));
        }
      };
      const snapError = error => console.log('hooks/state/useSupplierIndividuals: ', error.message, {error});
      const query = Firestore.query(Firestore.collection('Supplier-Individuals'), Firestore.orderBy('Name.Full'));
      unsubscribe = Firestore.onSnapshot(query, queryOptions, snapData, snapError);
    };
    const forgetState = () => {
      dispatch(individualsActions.forgetState());
      if (unsubscribe) unsubscribe();
    };

    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      if (user) getState();
      else forgetState();
    });
  }, [individuals, dispatch]);

  return individuals;
};

export default useSupplierIndividuals;
