import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@mui/material';

const AddressStreets = ({address, align, sx}) => {
  // Show either just Street1 or Street1/2, if Street2 exists
  if (!address?.Street2) return <Typography sx={sx} align={align}>{address?.Street1 || 'Data Missing'}</Typography>;
  return (
    <Fragment>
      <Typography sx={sx} align={align}>{address?.Street1 || 'Data Missing'}</Typography>
      <Typography sx={sx} align={align}>{address?.Street2 || 'Data Missing'}</Typography>
    </Fragment>
  );
};

AddressStreets.propTypes = {
  address: PropTypes.object.isRequired,
  align: PropTypes.string.isRequired,
  sx: PropTypes.object.isRequired,
};
AddressStreets.defaultProps = {
  align: 'left',
  sx: {},
};
export default React.memo(AddressStreets);
