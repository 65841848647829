import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Collapse, Typography, Grid, CircularProgress} from '@mui/material';
import {CheckCircleOutline, DeleteOutline} from 'icons';

const ImageProgressStage = props => {
  const isDeterminate = props.uploadProgress != null; // checks for ONLY null and undefined

  return (
    <Fragment>
      <Collapse in={props.stage === 'upload'}>
        <Grid container justifyContent='center' direction='column' spacing={0}>
          <Grid item xs container spacing={0} justifyContent='center'>
            <Typography variant='h6' style={styles.centerText}>
              The file is uploading.
            </Typography>
          </Grid>
          <Grid item xs style={styles.indicatorDisplay}>
            {isDeterminate && <Typography variant='h2' style={styles.centerText}>{props.uploadProgress || 0}%</Typography>}
            {!isDeterminate && <CircularProgress size={60} />}
          </Grid>
        </Grid>
      </Collapse>
      <Collapse in={props.stage === 'success'}>
        <Grid container justifyContent='center' direction='column' spacing={0}>
          <Grid item xs container justifyContent='center' spacing={0}>
            {props.delete && <DeleteOutline style={styles.successFontSize} />}
            {!props.delete && <CheckCircleOutline style={styles.successFontSize} />}
          </Grid>
          <Grid item xs>
            {Array.isArray(props.text) ? (
              props.text.map((textLine, index) => (<Typography variant='h6' style={styles.centerText} key={index} gutterBottom>
                {textLine}
              </Typography>))
            ) : (
              <Typography variant='h6' style={styles.centerText}>{props.text}</Typography>
            )}
          </Grid>
        </Grid>
      </Collapse>
    </Fragment>
  );
};

const styles = {
  centerText: {textAlign: 'center'},
  indicatorDisplay: {display: 'flex', justifyContent: 'center'},
  successFontSize: {fontSize: '8em'},
};
ImageProgressStage.propTypes = {
  // if text is passed an array, the component will seperate each of the elements into their own Typography tag during display.
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  stage: PropTypes.string.isRequired,
  delete: PropTypes.bool,
  uploadProgress: PropTypes.number,
};
export default ImageProgressStage;
