import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Auth, Functions, isProductionEnv} from 'firebaseConfig';
import {appUsersActions} from 'state/auth/slices/appUsers';

const useAuthAppUsers = () => {
  const appUsers = useSelector(state => state.auth.appUsers);
  const dispatch = useDispatch();
  const duplicateRender = useRef(false);

  useEffect(() => {
    // Prevent duplicate renders caused by React 18 strict mode
    if (duplicateRender.current) return;
    duplicateRender.current = true;

    // Prevent listener from running a second time
    dispatch(appUsersActions.setTouched(true));

    const getState = async () => {
      try {
        const corpcatalystuserlistUrl = (() => {
          if (isProductionEnv) return 'https://us-central1-kbi-catalyst-ec5cb.cloudfunctions.net/corpcatalystuserlist';
          else return 'https://us-central1-kbi-catalyst-dev.cloudfunctions.net/corpcatalystuserlist';
        })();
        const {data} = await Functions.httpsCallableFromURL(corpcatalystuserlistUrl)();

        // Make sure all users have a displayName field to populate fields
        const cleanData = data.map(user => {
          return {...user, displayName: user?.displayName || user?.email || user.uid};
        });

        dispatch(appUsersActions.setData(cleanData));
      }
      catch (error) {
        alert(error.message);
        console.log('hooks/state/useAuthGetAppUsers Error: ', error.message, {error});
      }
    };

    // Make sure user is authenticated before getting data
    Auth.onAuthStateChanged(user => {
      if (user) getState();
      else dispatch(appUsersActions.forgetState());
    });
  }, [dispatch]);

  return appUsers;
};

export default useAuthAppUsers;
