// https://www.codemzy.com/blog/fix-chunkloaderror-react
// A function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = (componentImport, name = 'General') => {
  return new Promise((resolve, reject) => {
    const sessionKey = `retry-${name}-refreshed`;
    // Check if the browser has already been refreshed
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(sessionKey) || 'false');

    // Attempt to import the component
    componentImport().then((component) => {
      // Successfully imported component; so reset the refresh sessionStorage property...
      window.sessionStorage.setItem(sessionKey, 'false');
      // And return the successfully imported component
      resolve(component);
    }).catch((error) => {
      // Component failed to be imported; Check to see if import failure caused by webpack Chunk Error
      const isChunkError = error.message.startsWith('Loading chunk');
      // If browser has not been refreshed yet and import failure caused by Chunk Error...
      if (!hasRefreshed && isChunkError) {
        // Set refresh sessionStorage property to true; Prevents duplicative refreshes from occuring
        window.sessionStorage.setItem(sessionKey, 'true');
        // Trigger browser refresh in attempt to clear cache and download latest webpack chunks.
        return window.location.reload();
      }
      // Trigger default error behaviour as browser already refreshed or not a webpack Chunk Error
      reject(error);
    });
  });
};

export default lazyRetry;
