import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  all: [],
  active: [],
  serialAll: [],
  gradeAll: [],
  pending: true,
  touched: false,
};

const converterIndexSlice = createSlice({
  name: 'converterUnitsIndex',
  initialState,
  reducers: {
    setConverterIndex: (state, action) => {
      state.all = action.payload.all;
      state.active = action.payload.active;
      state.serialAll = action.payload.serialAll;
      state.gradeAll = action.payload.gradeAll;
    },
    setPending: (state, action) => {
      state.pending = action.payload;
    },
    setTouched: (state, action) => {
      state.touched = action.payload;
    },
    forgetState: (state, action) => {
      state.all = [];
      state.active = [];
      state.serialAll = [];
      state.gradeAll = [];
      state.pending = true;
      state.touched = false;
    },
  },
});

export const converterIndexActions = converterIndexSlice.actions;

export default converterIndexSlice.reducer;
