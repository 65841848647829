import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: null,
  pending: true,
  touched: false,
};

const outboundInventorySlice = createSlice({
  name: 'outbound',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setPending: (state, action) => {
      state.pending = action.payload;
    },
    setTouched: (state, action) => {
      state.touched = action.payload;
    },
    forgetState: (state, action) => {
      state.data = initialState.data;
      state.pending = initialState.pending;
      state.touched = initialState.touched;
    },
  },
});


export const outboundInventoryActions = outboundInventorySlice.actions;

export default outboundInventorySlice.reducer;
