/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const SadComputer = (props) => (
  <SvgIcon viewBox='0 0 512 512' {...props}>
    <g>
      <path d="m512 376v-360h-512v360h206.789l-22.5 90h-63.289v30h270v-30h-63.289l-22.5-90zm-482-330h452v300h-452zm266.789 420h-81.578l22.5-90h36.578z" />
      <path d="m187.213 163.426 21.213-21.213-21.213-21.213 21.213-21.213-21.213-21.213-21.213 21.213-21.213-21.213-21.213 21.213 21.213 21.213-21.213 21.213 21.213 21.213 21.213-21.213z" />
      <path d="m367.213 78.574-21.213 21.213-21.213-21.213-21.213 21.213 21.213 21.213-21.213 21.213 21.213 21.213 21.213-21.213 21.213 21.213 21.213-21.213-21.213-21.213 21.213-21.213z" />
      <path d="m256 196c-57.897 0-105 47.103-105 105v15h30v-15c0-41.355 33.645-75 75-75s75 33.645 75 75v15h30v-15c0-57.897-47.103-105-105-105z" />
    </g>
  </SvgIcon>
);

export default SadComputer;
