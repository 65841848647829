import {useEffect, useRef /* useMemo */} from 'react';
import {batch, useDispatch, useSelector} from 'react-redux';
import {Auth, Firestore} from 'firebaseConfig';
import {inventoryRemovedActions} from 'state/inventory/slices/recentlyRemoved';
const queryOptions = {includeMetadataChanges: true};

const useInventoryRecentRemoved = () => {
  const removed = useSelector(state => state.inventory.removed);
  const dispatch = useDispatch();
  const duplicateRender = useRef(false);

  useEffect(() => {
    // Prevent duplicate renders caused by React 18 strict mode
    if (duplicateRender.current) return;
    duplicateRender.current = true;

    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (removed.touched) return;
    dispatch(inventoryRemovedActions.setTouched(true));

    let unsubscribe = null;
    // Create functions to get state or forget state depending on user's auth status
    const getState = () => {
      // Create JS Date 180 days prior to current date.
      const recentDate = new Date(new Date().setDate(new Date().getDate() - 180));
      const query = Firestore.query(
        Firestore.collection('Inventory-Containers'),
        Firestore.where('OnSite', '==', false),
        Firestore.where('System.RemovedOn', '>', recentDate),
      );
      const snapData = snap => {
        // Never use a snapshot fromCache or that hasPendingWrites (Data may be corrupt); Use pure data from server only;
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const data = {all: []};

        // If snap is empty and redux state is pending, then set pending to false to show first snap performed
        if (snap.empty && removed.pending) {
          batch(() => {
            dispatch(inventoryRemovedActions.setData(data));
            dispatch(inventoryRemovedActions.setPending(false));
          });
          return;
        }

        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            DocId: doc.id,
            AccumulationDate: docData.AccumulationDate.toMillis(),
            System: {
              ...docData.System,
              EnteredOn: docData.System.EnteredOn.toMillis(),
              RemovedOn: docData.System.RemovedOn.toMillis(),
            },
          };
          data.all.push(cleanDoc);
        });

        // Batch redux dispatch to push setData and SetPending simultaneously
        batch(() => {
          dispatch(inventoryRemovedActions.setData(data));
          dispatch(inventoryRemovedActions.setPending(false));
        });
      };
      const snapError = error => {
        alert(error.message);
        console.log('hooks/state/useRecentlyRemovedInventory Error: ', error.message, {error});
      };
      unsubscribe = Firestore.onSnapshot(query, queryOptions, snapData, snapError);
    };
    const forgetState = () => {
      dispatch(inventoryRemovedActions.forgetState());
      if (unsubscribe) unsubscribe();
    };

    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      if (user) getState();
      else forgetState();
    });
  }, [removed, dispatch]);

  return removed;
};

export default useInventoryRecentRemoved;
