import React from 'react';
import {Paper} from '@mui/material';
import PropTypes from 'prop-types';

const StyledPaper = props => {
  const {children, variant, ...rest} = props;

  if (variant === 'default') return <Paper {...rest} elevation={3} sx={{...rest.sx, ...styles.default}}>{children}</Paper>;
  else if (variant === 'table') return <Paper {...rest} elevation={3} sx={{...rest.sx, ...styles.table}}>{children}</Paper>;
};

const styles = {
  default: {
    'padding': {xs: '8px', sm: '16px', md: '16px', lg: '16px', xl: '16px'},
    '@media print': {boxShadow: 'none', border: '1px solid lightgray'},
  },
  table: {
    'padding': '0px',
    '@media print': {boxShadow: 'none', border: '1px solid lightgray'},
  },
};
StyledPaper.defaultProps = {variant: 'default'};
StyledPaper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.func, PropTypes.object, PropTypes.string]),
  variant: PropTypes.oneOf(['default', 'table']),
};
export default StyledPaper;
