import {configureStore} from '@reduxjs/toolkit';

// Import redux reducers
import AdminReducers from './admin/sliceReducer';
import AuthReducers from './auth/sliceReducer';
import IndexesReducers from './indexes/sliceReducer';
import InventoryReducer from './inventory/sliceReducer';
import SuppliersReducers from './suppliers/sliceReducer';
import UiReducers from './ui/sliceReducer';

// Initialize Redux Store
const store = configureStore({
  reducer: {
    admin: AdminReducers,
    auth: AuthReducers,
    indexes: IndexesReducers,
    inventory: InventoryReducer,
    suppliers: SuppliersReducers,
    ui: UiReducers,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    // Turn off serializable check in develepment mode to prevent console warning and slowdown when state is very large
    // https://redux-toolkit.js.org/api/serializabilityMiddleware
    serializableCheck: {
      ignoredActions: ['modal/setModal'],
      ignoredPaths: ['indexes', 'ui.modal.item'],
    },
  }),
});

export default store;
