import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {currentUserActions} from 'state/auth/slices/currentUser';

const useAuthCurrentUser = () => {
  const currentUser = useSelector(state => state.auth.currentUser.value);
  const dispatch = useDispatch();

  const setCurrentUser = useCallback(payload => {
    dispatch(currentUserActions.setCurrentUser(payload));
  }, [dispatch]);

  const forgetCurrentUser = useCallback(() => {
    dispatch(currentUserActions.forgetCurrentUser());
  }, [dispatch]);

  return {currentUser, setCurrentUser, forgetCurrentUser};
};

export default useAuthCurrentUser;
