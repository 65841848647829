/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const IndustrialScale = (props) => (
  <SvgIcon viewBox='0 0 66 66' {...props}>
    <g>
      <g>
        <path d="m65 51.9h-1.7c-.4-2.9-2.9-5.2-6-5.2h-27.8c-3.1 0-5.6 2.3-6 5.2h-3.3l-3.4-6.1c-.2-.3-.5-.5-.9-.5h-1.5v-23.3h6.2c.6 0 1-.4 1-1v-20c0-.6-.4-1-1-1h-19.5c-.6 0-1 .4-1 1v20c0 .6.4 1 1 1h6.2v23.2h-1.5c-.4 0-.7.2-.9.5l-3.4 6.1h-.5c-.6 0-1 .4-1 1v7.3c0 .6.4 1 1 1h4.3v3.9c0 .6.4 1 1 1h9.4c.6 0 1-.4 1-1v-3.9h32.5v3.9c0 .6.4 1 1 1h9.4c.6 0 1-.4 1-1v-3.9h4.4c.6 0 1-.4 1-1v-7.3c0-.5-.4-.9-1-.9zm-35.5-3.3h27.7c1.9 0 3.6 1.4 4 3.2h-35.6c.4-1.8 2-3.2 3.9-3.2zm-9.9-46.6v4.5h-17.5v-4.5zm-17.5 6.5h17.5v11.5h-17.5zm7.2 13.5h3.1v23.2h-3.1zm-2.9 25.2h9l2.6 4.6c-1.5 0-12.6 0-14.1 0zm8.3 16.8h-7.4v-2.9h7.4zm44 0h-7.4v-2.9h7.4zm5.3-4.9c-7.9 0-55.6 0-62 0v-5.3h.1 61.9z" />
      </g>
      <g>
        <path d="m6.8 10h-2.2c-.6 0-1 .4-1 1s.4 1 1 1h2.2c.6 0 1-.4 1-1 0-.5-.5-1-1-1z" />
      </g>
      <g>
        <path d="m6.8 13.3h-2.2c-.6 0-1 .4-1 1s.4 1 1 1h2.2c.6 0 1-.4 1-1s-.5-1-1-1z" />
      </g>
      <g>
        <path d="m6.8 16.6h-2.2c-.6 0-1 .4-1 1s.4 1 1 1h2.2c.6 0 1-.4 1-1s-.5-1-1-1z" />
      </g>
      <g>
        <path d="m9.8 12h2.2c.6 0 1-.4 1-1s-.4-1-1-1h-2.2c-.6 0-1 .4-1 1s.4 1 1 1z" />
      </g>
      <g>
        <path d="m9.8 15.3h2.2c.6 0 1-.4 1-1s-.4-1-1-1h-2.2c-.6 0-1 .4-1 1 0 .5.4 1 1 1z" />
      </g>
      <g>
        <path d="m9.8 18.6h2.2c.6 0 1-.4 1-1s-.4-1-1-1h-2.2c-.6 0-1 .4-1 1 0 .5.4 1 1 1z" />
      </g>
      <g>
        <path d="m15 12h2.2c.6 0 1-.4 1-1s-.4-1-1-1h-2.2c-.6 0-1 .4-1 1s.4 1 1 1z" />
      </g>
      <g>
        <path d="m15 15.3h2.2c.6 0 1-.4 1-1s-.4-1-1-1h-2.2c-.6 0-1 .4-1 1 0 .5.4 1 1 1z" />
      </g>
      <g>
        <path d="m15 18.6h2.2c.6 0 1-.4 1-1s-.4-1-1-1h-2.2c-.6 0-1 .4-1 1 0 .5.4 1 1 1z" />
      </g>
      <g>
        <path d="m27.9 28.6h12.9c.6 0 1-.4 1-1v-12.3c0-.6-.4-1-1-1h-12.9c-.6 0-1 .4-1 1v12.2c0 .6.4 1.1 1 1.1zm1-12.3h10.9v10.2h-10.9z" />
      </g>
      <g>
        <path d="m46 28.6h12.9c.6 0 1-.4 1-1v-12.3c0-.6-.4-1-1-1h-12.9c-.6 0-1 .4-1 1v12.2c0 .6.5 1.1 1 1.1zm1-12.3h10.9v10.2h-10.9z" />
      </g>
      <g>
        <path d="m27.9 45.2h12.9c.6 0 1-.4 1-1v-12.2c0-.6-.4-1-1-1h-12.9c-.6 0-1 .4-1 1v12.2c0 .5.4 1 1 1zm1-12.2h10.9v10.2h-10.9z" />
      </g>
      <g>
        <path d="m46 45.2h12.9c.6 0 1-.4 1-1v-12.2c0-.6-.4-1-1-1h-12.9c-.6 0-1 .4-1 1v12.2c0 .5.5 1 1 1zm1-12.2h10.9v10.2h-10.9z" />
      </g>
    </g>
  </SvgIcon>
);

export default IndustrialScale;
