import {useEffect, useRef} from 'react';
import {batch, useDispatch, useSelector} from 'react-redux';
import {Auth, Firestore} from 'firebaseConfig';
import {kbiLocationsActions} from 'state/admin/slices/kbiLocations';
const queryOptions = {includeMetadataChanges: true};

const useAdminKbiLocations = () => {
  const kbiLocations = useSelector(state => state.admin.kbiLocations);
  const dispatch = useDispatch();
  const duplicateRender = useRef(false);

  useEffect(() => {
    // Prevent duplicate renders caused by React 18 strict mode
    if (duplicateRender.current) return;
    duplicateRender.current = true;

    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (kbiLocations.touched) return;
    dispatch(kbiLocationsActions.setTouched(true));

    let unsubscribe = null;
    // Create functions to get state or forget state depending on user's auth status
    const getState = () => {
      const query = Firestore.query(
        Firestore.collection('Admin-Kbi-Locations'),
        Firestore.orderBy('Name')
      );
      const snapData = snap => {
        // Never use a snapshot fromCache or that hasPendingWrites (Data may be corrupt); Use pure data from server only;
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const data = {all: [], active: []};
        // If snap is empty and redux state is pending, then set pending to false to show first snap performed
        if (snap.empty && kbiLocations.pending) {
          batch(() => {
            dispatch(kbiLocationsActions.setData(data));
            dispatch(kbiLocationsActions.setPending(false));
          });
          return;
        }

        snap.forEach(doc => {
          const docData = doc.data();
          const cleanData = {
            ...docData,
            DocId: doc.id,
            System: {
              ...docData.System,
              EnteredOn: docData.System.EnteredOn.toMillis(),
            },
          };
          data.all.push(cleanData);
          if (docData.Active) data.active.push(cleanData);
        });

        // Batch redux dispatch to push setData and SetPending simultaneously
        batch(() => {
          dispatch(kbiLocationsActions.setData(data));
          dispatch(kbiLocationsActions.setPending(false));
        });
      };
      const snapError = error => {
        alert(error.message);
        console.log('hooks/state/useAdminKbiLocations Error: ', error.message, {error});
      };
      unsubscribe = Firestore.onSnapshot(query, queryOptions, snapData, snapError);
    };
    const forgetState = () => {
      dispatch(kbiLocationsActions.forgetState());
      if (unsubscribe) unsubscribe();
    };

    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      if (user) getState();
      else forgetState();
    });
  }, [kbiLocations, dispatch]);

  return kbiLocations;
};

export default useAdminKbiLocations;
