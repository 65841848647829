import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  Purchases: true,
  Locations: true,
  Contacts: true,
  Documents: true,
};

const accountViewSlice = createSlice({
  name: 'accountView',
  initialState,
  reducers: {
    setAccountView: (state, action) => {
      if (typeof action?.payload?.Purchases === 'boolean') state.Purchases = action.payload.Purchases;
      if (typeof action?.payload?.Locations === 'boolean') state.Locations = action.payload.Locations;
      if (typeof action?.payload?.Contacts === 'boolean') state.Contacts = action.payload.Contacts;
      if (typeof action?.payload?.Documents === 'boolean') state.Documents = action.payload.Documents;
    },
  },
});

export const accountViewActions = accountViewSlice.actions;

export default accountViewSlice.reducer;
