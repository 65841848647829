import React, {useCallback} from 'react';
import {Alert, IconButton, Snackbar as Snack} from '@mui/material';
import {Close} from 'icons';
import {useUiSnackbar} from 'state';

const Snackbar = () => {
  const {snackbar, clearSnackbar} = useUiSnackbar();
  const handleClose = useCallback((event, reason) => {
    if (reason === 'clickaway') return;
    clearSnackbar();
  }, [clearSnackbar]);

  return (
    <Snack
      anchorOrigin={snackbar.anchorOrigin}
      autoHideDuration={snackbar.autoHideDuration}
      open={snackbar.open}
      onClose={handleClose}
      action={<IconButton size='small' onClick={handleClose}><Close fontSize='small' /></IconButton>}
    >
      <Alert onClose={handleClose} severity={snackbar.severity} sx={styles.alert} variant='filled'>
        {snackbar.message}
      </Alert>
    </Snack>
  );
};

const styles = {
  alert: {width: '100%'},
};
export default Snackbar;
