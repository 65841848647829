import {useEffect, useRef, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {RemoteConfig} from 'firebaseConfig';
const FIFTEEN_MINUTES = 900000; // In milliseconds

/*
 * The purpose of this component is to check the current build version of the React web app. If the version number has changed,
 * then an alert dialog will be displayed asking the user to refresh their browser. Refreshing the browser should ensure the
 * user now has the latest build of the web app. The user will also be given the option to dismiss the alert dialog and reset
 * the time for the next time the current build version is checked; this will allow the user time to finish up particular tasks
 * before resetting their browser.
 *
 * To trigger, Firebase Remote Config BUILD_VERSION property must be updated:
 *  - Prod: https://console.firebase.google.com/u/0/project/kbi-catalyst-ec5cb/config
 *  - Dev: https://console.firebase.google.com/u/0/project/kbi-catalyst-dev/config
 */

const BuildVersionCheck = () => {
  const buildRef = useRef('');
  const intervalRef = useRef(null);
  const initialCheckRef = useRef(false);

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const checkBuildVersion = async () => {
      // Get the latest BUILD_VERSION value from Firebase Remote Config
      await RemoteConfig.fetchAndActivate();
      const configBuildVersion = await RemoteConfig.getValue('BUILD_VERSION').asString();

      // If there is no buildRef.current, then the browser was just refreshed and the buildRef.current should
      // be set to the currently pulled BUILD_VERSION to store and compare with possible future updates
      if (!buildRef.current) buildRef.current = configBuildVersion;
      // If the BUILD_VERSION ever changes from the stored buildRef.current set above, then the alert dialog
      // should be opened to notify user that the browser should be refreshed.
      else if (buildRef.current !== configBuildVersion && !modalOpen) setModalOpen(true);
    };

    // Run checkBuildVersion function immediately on component mount
    if (!initialCheckRef.current) checkBuildVersion();
    initialCheckRef.current = true;

    // Store interval id, so that the interval can be cleared as needed.
    intervalRef.current = setInterval(checkBuildVersion, FIFTEEN_MINUTES);

    // Clear interval in order to reset timer every time the alert dialog is either opened or closed
    return () => clearInterval(intervalRef.current);
  }, [modalOpen]);

  return (<>
    <Dialog open={modalOpen}>
      <DialogTitle>
        New Version Deployed
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{textAlign: 'justify'}}>
          A new version of this web app has been deployed. To prevent system errors
          and/or crashes, refresh the web browser at your next convenience.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='error' onClick={() => setModalOpen(false)}>
          Dismiss
        </Button>
        <Button variant='contained' color='error' disableElevation onClick={() => window.location.reload()}>
          Refresh Browser Now
        </Button>
      </DialogActions>
    </Dialog>
  </>);
};

export default BuildVersionCheck;
