/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const SignatureDigital = (props) => (
  <SvgIcon viewBox='0 0 32 32' {...props}>
    <path id="path1314" d="m15.7451 4.00015a1.0001 1.0001 0 0 0 -.6934.29297l-11.7578 11.75781a1.0001 1.0001 0 0 0 -.293.70703v4.24218a1.0001 1.0001 0 0 0 1 1h4.2422a1.0001 1.0001 0 0 0 .7071-.29296l11.7578-11.75782a1.0001 1.0001 0 0 0 0-1.41406l-4.2422-4.24218a1.0001 1.0001 0 0 0 -.7207-.29297z" font-variant-ligatures="normal" font-variant-position="normal" font-variant-caps="normal" font-variant-numeric="normal" font-variant-alternates="normal" font-feature-settings="normal" text-indent="0" text-align="start" text-decoration-line="none" text-decoration-style="solid" text-decoration-color="rgb(0,0,0)" text-transform="none" text-orientation="mixed" white-space="normal" shape-padding="0" isolation="auto" mix-blend-mode="normal" solid-color="rgb(0,0,0)" solid-opacity="1" vectorEffect="none" paintOrder="normal" />
    <path id="path1303" d="m17.6365 18.0004c-.2598.008-.5064.11621-.6875.30245l-4.5917 4.58747c-.3234.3231-.5479.70705-.6914 1.11417h-7.6501c-.5638-.008-1.0244.44977-1.0157 1.00687.01.55712.4642.99879 1.0157.99125h7.6463c.143.41152.3687.7997.6952 1.12589 1.163 1.16187 3.0811 1.16194 4.244 0l2.83-2.82741c.132.30842.3093.5549.584.8293 1.1629 1.16183 3.0791 1.16183 4.2421 0l1.1367-1.13565h2.5917c1.3522.0191 1.3522-2.01722 0-1.99811h-3.0058c-.2652.00006-.5195.10534-.707.29269l-1.4297 1.42833c-.404.40363-1.0099.40364-1.414 0-.404-.40359-.4039-1.00913 0-1.41272l.5918-.59123c.9031-.94157-.4715-2.31507-1.414-1.41274l-.5918.59124-4.8281 4.82357c-.4037.40353-1.0119.4036-1.4159 0s-.404-1.00912 0-1.41273l4.5917-4.58746c.654-.63519.1853-1.74198-.7265-1.71518z" font-variant-ligatures="normal" font-variant-position="normal" font-variant-caps="normal" font-variant-numeric="normal" font-variant-alternates="normal" font-feature-settings="normal" text-indent="0" text-align="start" text-decoration-line="none" text-decoration-style="solid" text-decoration-color="rgb(0,0,0)" text-transform="none" text-orientation="mixed" white-space="normal" shape-padding="0" isolation="auto" mix-blend-mode="normal" solid-color="rgb(0,0,0)" solid-opacity="1" vectorEffect="none" />
  </SvgIcon>
);

export default SignatureDigital;
