import {useEffect, useRef} from 'react';
import {batch, useDispatch, useSelector} from 'react-redux';
import {Auth, Firestore} from 'firebaseConfig';
import {converterIndexActions} from 'state/indexes/slices/convertersReducer';
const queryOptions = {includeMetadataChanges: true};

const useConverterUnitsIndex = () => {
  const converterUnitsIndex = useSelector(state => state.indexes.converters);
  const dispatch = useDispatch();
  const duplicateRender = useRef(false);

  useEffect(() => {
    // Prevent duplicate renders caused by React 18 strict mode
    if (duplicateRender.current) return;
    duplicateRender.current = true;

    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (converterUnitsIndex.touched) return;
    dispatch(converterIndexActions.setTouched(true));

    let unsubscribe = null;
    // Create functions to get state or forget state depending on user's auth status
    const getState = () => {
      const query = Firestore.collection('Converter-Units-Index');
      const snapData = snap => {
        // Never use a snapshot fromCache or that hasPendingWrites (Data may be corrupt); Use pure data from server only;
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const data = {all: [], active: [], serialAll: [], gradeAll: []};

        // If snap is empty and redux state is pending, then set pending to false to show first snap performed
        if (snap.empty && converterUnitsIndex.pending) {
          batch(() => {
            dispatch(converterIndexActions.setConverterIndex(data));
            dispatch(converterIndexActions.setPending(false));
          });
          return;
        }

        // Combine all document Units arrays into a single array.
        let Units = [];
        snap.forEach(doc => Units.push(...doc.data().Units));
        Units = Units.map(unit => ({...unit, LastAssay: unit?.LastAssay ? unit.LastAssay.toMillis() : null}));
        // Create specific array categories of documents for easier access
        Units.forEach(unit => {
          data.all.push(unit);
          if (unit.Active) data.active.push(unit);
          if (unit.UnitType === 'Serial') data.serialAll.push(unit);
          if (unit.UnitType === 'Grade') data.gradeAll.push(unit);
        });

        // Batch redux dispatch to push setData and SetPending simultaneously
        batch(() => {
          dispatch(converterIndexActions.setConverterIndex(data));
          dispatch(converterIndexActions.setPending(false));
        });
      };
      const snapError = error => console.log('hooks/state/useConverterUnitsIndex Error: ', error.message, {error});
      unsubscribe = Firestore.onSnapshot(query, queryOptions, snapData, snapError);
    };
    const forgetState = () => {
      dispatch(converterIndexActions.forgetState());
      if (unsubscribe) unsubscribe();
    };

    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      if (user) getState();
      else forgetState();
    });
  }, [converterUnitsIndex, dispatch]);

  return converterUnitsIndex;
};

export default useConverterUnitsIndex;
