/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Converter = (props) => (
  <SvgIcon viewBox='0 0 512 512' {...props}>
    <path d='m386.256 55.841 69.903 69.903 20.89-20.89 13.738 13.738 21.213-21.213-97.379-97.379-21.213 21.213 13.738 13.738z' />
    <path d='m45.478 265.341 16.651 83.254 101.276 101.277 83.254 16.65z' />
    <path d='m126.505 455.397-69.903-69.902-21.651 21.651-13.738-13.738-21.213 21.213 97.379 97.379 21.213-21.213-13.738-13.738z' />
    <path d='m236.733 59.296-177.437 177.438 215.971 215.971 177.438-177.438zm16.621 314.597-115.247-115.247 21.213-21.213 115.247 115.247zm49.663-49.664-115.247-115.246 21.213-21.213 115.247 115.247zm-65.584-164.909 21.213-21.213 115.247 115.247-21.213 21.213z' />
    <path d='m350.499 62.509-85.158-17.031 201.181 201.181-17.031-85.158z' />
  </SvgIcon>
);

export default Converter;
