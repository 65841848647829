/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Purchases = (props) => (
  <SvgIcon viewBox='0 0 510 510' {...props}>
    <g>
      <path d='m270 0c-10 0-170 0-180 0v30h180z' />
      <path d='m204.181 450c-20.343 0-123.677 0-144.181 0 0-10.493 0-19.508 0-30.001 9.906 0 114.428.001 130.301.001-10.01-28.218-12.982-59.168-7.787-90-32.491 0-108.612 0-122.514 0 0-10.493 0-19.508 0-30.001h130.296c24.766-69.839 91.482-119.999 169.704-119.999v-150c-.042 0-38.368 0-90 0v30c-10 0-170 0-180 0 0-3.652 0-16.827 0-30-49.225 0-86.42 0-90 0v480h260.619c-23.113-15.364-42.478-35.929-56.438-60zm-84.181-315h105v30c-21.368 0-83.587 0-105 0zm0 60h60v30h-60zm-60-60h30v30h-30zm0 60h30v30h-30zm0 164.999h75v30.001h-75z' />
      <path d='m360 210c-82.843 0-150 67.157-150 150s67.157 150 150 150 150-67.157 150-150-67.157-150-150-150zm45 104.999c-18.228 0-42.155 0-60.001 0v30h60.001v90.001h-30v30c-10.492 0-19.509 0-30.001 0v-30h-30v-30h60.001v-30c-17.848 0-41.781 0-60.001 0v-90.001h30v-29.999h30.001v29.999h30z' />
    </g>
  </SvgIcon>
);

export default Purchases;
