import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@mui/material';

const AddressArea = ({address, align, sx}) => {
  // Display pursuant to Country's standard area format
  const CityMunicipality = address?.CityMunicipality || 'Data Missing';
  const StateAdminArea = address?.StateAdminArea || 'Data Missing';
  const PostalCode = address?.PostalCode || 'Data Missing';

  if (address.Country === 'United States') return <Typography sx={sx} align={align}>{`${CityMunicipality}, ${StateAdminArea} ${PostalCode}`}</Typography>;
  return <Typography sx={sx} align={align}>Data Missing</Typography>;
};

AddressArea.propTypes = {
  address: PropTypes.object.isRequired,
  align: PropTypes.string.isRequired,
  sx: PropTypes.object.isRequired,
};
AddressArea.defaultProps = {
  align: 'left',
  sx: {},
};
export default React.memo(AddressArea);
