// Allowable licenses for business locations
export const GLOBAL_BUSINESS_LICENSE = 'Business License';
export const GLOBAL_OPERATIONAL_LICENSE = 'Operational License';
export const GLOBAL_TAX_ID = 'Tax Id (Federal)';
export const CA_AUTO_DISMANTLER_LICENSE = 'CA Auto Dismantler';
export const CA_AUTO_REPAIR_DEALER_LICENSE = 'CA Auto Repair Dealer';
export const CA_VEHICLE_DEALER_LICENSE = 'CA Vehicle Dealer';
export const CA_VEHICLE_LESSOR_RETAILER_LICENSE = 'CA Vehicle Lessor/Retailer';
export const CA_VEHICLE_MANUFACTURER_LICENSE = 'CA Vehicle Manufacturer';
export const TX_METAL_RECYCLING_ENTITY_LICENSE = 'TX Metal Recycling Entity';
export const TX_MOTOR_VEHICLE_DEALER_LICENSE = 'TX Motor Vehicle Dealer';
export const TX_SALVAGE_DEALER_LICENSE = 'TX Salvage Dealer';
export const TX_TOW_COMPANY_LICENSE = 'TX Tow Company';
export const TX_TOW_OPERATOR_LICENSE = 'TX Tow Operator';
export const TX_USED_AUTO_PARTS_RECYCLER_LICENSE = 'TX Used Auto Parts Recycler';
export const TX_VEHICLE_MFG_DISTRIBUTOR_LICENSE = 'TX Vehicle Manufacturer / Distributor';
export const TX_VEHICLE_STORAGE_FACILITY_LICENSE = 'TX Vehicle Storage Facility';

export const GLOBAL_LICENSE_TYPES = [
  GLOBAL_BUSINESS_LICENSE,
  GLOBAL_OPERATIONAL_LICENSE,
  GLOBAL_TAX_ID,
];
export const CA_LICENSE_TYPES = [
  CA_AUTO_DISMANTLER_LICENSE,
  CA_AUTO_REPAIR_DEALER_LICENSE,
  CA_VEHICLE_DEALER_LICENSE,
  CA_VEHICLE_LESSOR_RETAILER_LICENSE,
  CA_VEHICLE_MANUFACTURER_LICENSE,
];
export const TX_LICENSE_TYPES = [
  TX_METAL_RECYCLING_ENTITY_LICENSE,
  TX_MOTOR_VEHICLE_DEALER_LICENSE,
  TX_SALVAGE_DEALER_LICENSE,
  TX_TOW_COMPANY_LICENSE,
  TX_TOW_OPERATOR_LICENSE,
  TX_USED_AUTO_PARTS_RECYCLER_LICENSE,
  TX_VEHICLE_MFG_DISTRIBUTOR_LICENSE,
  TX_VEHICLE_STORAGE_FACILITY_LICENSE,
];

// States and provinces for United States, Canada and Mexico
export const usStates = [
  {value: 'AK'},
  {value: 'AL'},
  {value: 'AR'},
  {value: 'AS'},
  {value: 'AZ'},
  {value: 'CA'},
  {value: 'CO'},
  {value: 'CT'},
  {value: 'DC'},
  {value: 'DE'},
  {value: 'FL'},
  {value: 'GA'},
  {value: 'GU'},
  {value: 'HI'},
  {value: 'IA'},
  {value: 'ID'},
  {value: 'IL'},
  {value: 'IN'},
  {value: 'KS'},
  {value: 'KY'},
  {value: 'LA'},
  {value: 'MA'},
  {value: 'MD'},
  {value: 'ME'},
  {value: 'MI'},
  {value: 'MN'},
  {value: 'MO'},
  {value: 'MS'},
  {value: 'MT'},
  {value: 'NC'},
  {value: 'ND'},
  {value: 'NE'},
  {value: 'NH'},
  {value: 'NJ'},
  {value: 'NM'},
  {value: 'NV'},
  {value: 'NY'},
  {value: 'OH'},
  {value: 'OK'},
  {value: 'OR'},
  {value: 'PA'},
  {value: 'PR'},
  {value: 'RI'},
  {value: 'SC'},
  {value: 'SD'},
  {value: 'TN'},
  {value: 'TX'},
  {value: 'UT'},
  {value: 'VA'},
  {value: 'VI'},
  {value: 'VT'},
  {value: 'WA'},
  {value: 'WI'},
  {value: 'WV'},
  {value: 'WY'},
];
export const canadaProvinces = [
  {value: 'AB'},
  {value: 'BC'},
  {value: 'MB'},
  {value: 'NB'},
  {value: 'NL'},
  {value: 'NT'},
  {value: 'NS'},
  {value: 'NU'},
  {value: 'ON'},
  {value: 'PE'},
  {value: 'QC'},
  {value: 'SK'},
  {value: 'YT'},
];
export const mexicanStates = [
  {value: 'AG'},
  {value: 'BN'},
  {value: 'BS'},
  {value: 'CH'},
  {value: 'CI'},
  {value: 'CL'},
  {value: 'CP'},
  {value: 'CS'},
  {value: 'DF'},
  {value: 'DG'},
  {value: 'GE'},
  {value: 'GJ'},
  {value: 'HD'},
  {value: 'JA'},
  {value: 'MC'},
  {value: 'MR'},
  {value: 'MX'},
  {value: 'NA'},
  {value: 'NL'},
  {value: 'OA'},
  {value: 'PU'},
  {value: 'QE'},
  {value: 'QI'},
  {value: 'SI'},
  {value: 'SL'},
  {value: 'SO'},
  {value: 'TA'},
  {value: 'TB'},
  {value: 'TL'},
  {value: 'VC'},
  {value: 'YU'},
  {value: 'ZA'},
];

// Common Regex variables
export const postalRegex = /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/im;
export const mexicoPostalRegex = /[0-9]{5}/g;
export const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im; // eslint-disable-line no-useless-escape

// Used to customize purchase sheets depending on jurisdiction and supplier type
export const regions = {
  ARIZONA: 'Arizona',
  CALIFORNIA_NORTHERN: 'California (Northern)',
  CALIFORNIA_SOUTHERN: 'California (Southern)',
  NEVADA: 'Nevada',
  TEXAS: 'Texas',
};
export const regionList = [
  regions.ARIZONA,
  regions.CALIFORNIA_NORTHERN,
  regions.CALIFORNIA_SOUTHERN,
  regions.NEVADA,
  regions.TEXAS,
];

// Used to populate Admin-Inventory-Items and Inventory-Containers
// inboundInv used to determine whether category can be added to Admin-Inventory-Items document
export const processCategories = [
  {value: 'Aftermarket', inboundInv: true, isProcessMedia: true},
  {value: 'Beads', inboundInv: true, isProcessMedia: true},
  {value: 'Carbide', inboundInv: true, isProcessMedia: true},
  {value: 'Domestic', inboundInv: true, isProcessMedia: true},
  {value: 'Foreign', inboundInv: true, isProcessMedia: true},
  {value: 'GM', inboundInv: true, isProcessMedia: true},
  {value: 'Mixed', inboundInv: false, isProcessMedia: true},
  {value: 'OEM', inboundInv: false, isProcessMedia: true},
  {value: 'Sensors', inboundInv: true, isIntactMaterial: true},
  {value: 'Stainless', inboundInv: true, isIntactMaterial: true},
  {value: 'APC Dust', inboundInv: false, isProcessByproduct: true},
  {value: 'APC Filters', inboundInv: false, isProcessByproduct: true},
  {value: 'Screens', inboundInv: false, isScrapMetal: true},
  {value: 'Wire', inboundInv: false, isScrapMetal: true},
];

// Used as the default options for the Inventory Container flag field
export const containerFlags = [
  {value: 'DFHC-FG'},
  {value: 'DFHC-JA'},
  {value: 'DFHC-NM'},
  {value: 'DFHC-TN'},
  {value: 'F-KC'},
  {value: 'SA-Alameda'},
  {value: 'SA-Carson'},
];
