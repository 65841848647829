import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  open: false,
};

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setDrawer: (state, action) => {
      state.open = action.payload;
    },
    setDrawerOpen: (state, action) => {
      state.open = true;
    },
    setDrawerClose: (state, action) => {
      state.open = false;
    },
    setDrawerToggle: (state, action) => {
      state.open = !state.open;
    },
  },
});

export const drawerActions = drawerSlice.actions;

export default drawerSlice.reducer;
