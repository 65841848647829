import {useEffect, useRef} from 'react';
import {batch, useDispatch, useSelector} from 'react-redux';
import {Auth, Firestore} from 'firebaseConfig';
import {metalMarketActions} from 'state/admin/slices/metalMarket';
const queryOptions = {includeMetadataChanges: true};

const useAdminMetalMarkets = () => {
  const metalMarkets = useSelector(state => state.admin.metalMarkets);
  const dispatch = useDispatch();
  const duplicateRender = useRef(false);

  useEffect(() => {
    // Prevent duplicate renders caused by React 18 strict mode
    if (duplicateRender.current) return;
    duplicateRender.current = true;

    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (metalMarkets.touched) return;
    dispatch(metalMarketActions.setTouched(true));

    let unsubscribe = null;
    // Create functions to get state or forget state depending on user's auth status
    const getState = () => {
      const query = Firestore.query(
        Firestore.collection('Admin-Metal-Markets'),
        Firestore.orderBy('MarketDate', 'desc'),
        Firestore.limit(100)
      );
      const snapData = snap => {
        // Never use a snapshot fromCache or that hasPendingWrites (Data may be corrupt); Use pure data from server only;
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const data = {all: [], current: null};

        // If snap is empty and redux state is pending, then set pending to false to show first snap performed
        if (snap.empty && metalMarkets.pending) {
          batch(() => {
            dispatch(metalMarketActions.setData(data));
            dispatch(metalMarketActions.setPending(false));
          });
          return;
        }

        snap.forEach(doc => {
          const docData = doc.data();
          const cleanData = {
            ...docData,
            DocId: doc.id,
            MarketDate: docData.MarketDate.toMillis(),
            System: {
              ...docData.System,
              EnteredOn: docData.System.EnteredOn.toMillis(),
            },
          };
          data.all.push(cleanData);
          if (!data.current || data.current.MarketDate < cleanData.MarketDate) data.current = cleanData;
        });

        // Batch redux dispatch to push setData and SetPending simultaneously
        batch(() => {
          dispatch(metalMarketActions.setData(data));
          dispatch(metalMarketActions.setPending(false));
        });
      };
      const snapError = error => {
        alert(error.message);
        console.log('hooks/state/useAdminMetalMarkets Error: ', error.message, {error});
      };
      unsubscribe = Firestore.onSnapshot(query, queryOptions, snapData, snapError);
    };
    const forgetState = () => {
      dispatch(metalMarketActions.forgetState());
      if (unsubscribe) unsubscribe();
    };

    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      if (user) getState();
      else forgetState();
    });
  }, [metalMarkets, dispatch]);

  return metalMarkets;
};

export default useAdminMetalMarkets;
