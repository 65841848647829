/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Containers = (props) => (
  <SvgIcon viewBox='0 0 60 57' {...props}>
    <path id="Shape" d="m57 24h-9.786c.5015341-.5457648.7817569-1.2587999.786-2v-19c0-1.65685425-1.3431458-3-3-3h-19c-.7639211.00024057-1.4986774.29335573-2.053.819l-9.212 6.47c-1.0551795.4906837-1.7314083 1.54731563-1.735 2.711v14c-.7639211.0002406-1.4986774.2933557-2.053.819l-9.212 6.47c-1.05517947.4906837-1.73140831 1.5473156-1.735 2.711v20c0 1.6568542 1.34314575 3 3 3h21c.740399-.0026037 1.4533329-.2806479 2-.78.5466671.4993521 1.259601.7773963 2 .78h21c.8113742-.0004402 1.5876103-.3311529 2.15-.916l7.958-7.958c.570437-.5603504.8918383-1.3263808.892-2.126v-19c-.0082058-1.6534434-1.3465566-2.9917942-3-3zm-6.666 7.327c-.4129299-.2119451-.8698636-.3239521-1.334-.327h-8.766l5-5h11.188zm-26.334-.327h-8c-.5522847 0-1-.4477153-1-1v-20c0-.55228475.4477153-1 1-1h21c.5522847 0 1 .44771525 1 1v20c.0008743.2617573-.1015372.5132943-.285.7l-.02.02c-.1862728.1804188-.4356784.2808987-.695.28zm15.727-22.234 6.273-5.489v18.723c-.0014251.2642598-.1096223.5167198-.3.7l-5.7 5.709v-18.409c-.0011951-.42617997-.0943131-.84708685-.273-1.234zm-1.393-1.439c-.4129299-.21194509-.8698636-.32395209-1.334-.327h-7.085l6.428-5h8.079zm-13.169-4.919c.0506099-.03555351.0978121-.07572565.141-.12.183311-.18524278.4333915-.28902259.694-.288l7.085-.00000749-6.428 5.00000749h-8.03zm-13 24c.0506099-.0355535.0978121-.0757257.141-.12.183311-.1852428.4333915-.2890226.694-.288v4c.0033144.3414397.0655622.679743.184 1h-7.557zm-9.165 28.592c-.55228475 0-1-.4477153-1-1v-20c0-.5522847.44771525-1 1-1h21c.5522847 0 1 .4477153 1 1v20c-.0000381.2617729-.1027208.5130941-.286.7l-.018.017c-.1859805.1818493-.4358891.2834644-.696.283zm25 0c-.5522847 0-1-.4477153-1-1v-20c0-.5522847.4477153-1 1-1h21c.5522847 0 1 .4477153 1 1v20c-.0017673.255461-.1012377.5005589-.278.685l-.022.015-.014.016c-.1837482.1791927-.4293608.2808749-.686.284zm29.7-8.3-5.7 5.7v-18.4c-.0011951-.42618-.0943131-.8470868-.273-1.234l6.273-5.489v18.723c-.0014251.2642598-.1096223.5167198-.3.7z" />
    <path id="Shape" d="m23 23h-4c-1.1045695 0-2 .8954305-2 2v2c0 1.1045695.8954305 2 2 2h4c1.1045695 0 2-.8954305 2-2v-2c0-1.1045695-.8954305-2-2-2zm-4 4v-2h4v2z" />
    <path id="Shape" d="m35 23h-3c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h3c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z" />
    <path id="Shape" d="m35 27h-3c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h3c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z" />
    <path id="Shape" d="m10 47h-4c-1.1045695 0-2 .8954305-2 2v2c0 1.1045695.8954305 2 2 2h4c1.1045695 0 2-.8954305 2-2v-2c0-1.1045695-.8954305-2-2-2zm-4 4v-2h4v2z" />
    <path id="Shape" d="m22 47h-3c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h3c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z" />
    <path id="Shape" d="m22 51h-3c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h3c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z" />
    <path id="Shape" d="m35 47h-4c-1.1045695 0-2 .8954305-2 2v2c0 1.1045695.8954305 2 2 2h4c1.1045695 0 2-.8954305 2-2v-2c0-1.1045695-.8954305-2-2-2zm-4 4v-2h4v2z" />
    <path id="Shape" d="m47 47h-3c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h3c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z" />
    <path id="Shape" d="m47 51h-3c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h3c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z" />
  </SvgIcon>
);

export default Containers;
