import {combineReducers} from 'redux';
import {InventoryItemsReducer, KbiLocationsReducer, LeaseRatesReducer, LocationUnitsReducer} from './slices';
import {ManufacturersReducer, MetalMarketsReducer, OutboundLocationsReducer, SupplierTiersReducer} from './slices';

export default combineReducers({
  inventoryItems: InventoryItemsReducer,
  kbiLocations: KbiLocationsReducer,
  leaseRates: LeaseRatesReducer,
  locationUnits: LocationUnitsReducer,
  manufacturers: ManufacturersReducer,
  metalMarkets: MetalMarketsReducer,
  outboundLocations: OutboundLocationsReducer,
  supplierTiers: SupplierTiersReducer,
});
