import {useDispatch, useSelector} from 'react-redux';
import {accountViewActions} from 'state/ui/slices/accountView';

const useUiAccountView = () => {
  const dispatch = useDispatch();
  const {Purchases, Locations, Contacts, Documents} = useSelector(state => state.ui.accountView);

  const setAccountView = payload => dispatch(accountViewActions.setAccountView(payload));

  return {setAccountView, Purchases, Locations, Contacts, Documents};
};

export default useUiAccountView;
