import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  value: null,
};

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.value = action.payload;
    },
    forgetCurrentUser: (state, action) => {
      state.value = initialState.value;
    },
  },
});

export const currentUserActions = currentUserSlice.actions;

export default currentUserSlice.reducer;
