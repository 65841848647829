/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Signature = (props) => (
  <SvgIcon viewBox='0 0 512 512' {...props}>
    <path d='m508.843 108.918-21.213-21.212-26.24 26.24c-17.195-9.879-39.569-7.49-54.246 7.188l-8.856 8.856v-129.99h-316.014l-79.117 79.117v432.883h395.131v-245.467l77.128-77.128c14.678-14.678 17.068-37.052 7.188-54.247zm-296.32 253.808 21.3 21.3-36.915 15.615zm44.786 2.359-25.845-25.844 165.073-165.074 25.845 25.845zm-166.862-330.832v53.037h-53.037zm277.841 447.747h-335.131v-364.71h87.29v-87.29h247.841v129.99l-47.3 47.3h-241.115v30h211.115l-30 30h-181.115v30h151.115l-40.483 40.483-26.095 61.692-20.966-20.965-33.5 33.5h-46.787v30h59.213l21.074-21.074 23.831 23.822 91.501-38.704 109.512-109.512zm85.915-313.808-10.607 10.607-25.845-25.846 10.607-10.607c5.85-5.848 15.364-5.849 21.214 0l4.632 4.632c5.847 5.849 5.847 15.365-.001 21.214z' />
    <path d='m122.116 147.29h157.213v30h-157.213z' />
  </SvgIcon>
);

export default Signature;
