import React from 'react';
import PropTypes from 'prop-types';


const ConditionalRender = ({children, show}) => {
  if (!show) return null;
  else return children;
};

ConditionalRender.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
};
export default React.memo(ConditionalRender);
