/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Average = (props) => (
  <SvgIcon viewBox='0 0 512 512' {...props}>
    <g>
      <path d='m436 360h-165v-30.76c84.08-7.624 150-78.433 150-164.24 0-90.979-74.016-165-165-165-90.979 0-165 74.016-165 165 0 85.779 65.892 156.614 150 164.24v30.76h-165c-8.291 0-15 6.709-15 15v45h-15c-8.291 0-15 6.709-15 15 0 3.333 0 65.246 0 62 0 8.291 6.709 15 15 15h60c8.291 0 15-6.709 15-15v-62c0-8.291-6.709-15-15-15h-15c0-3.021 0-33.021 0-30h90c0 3.021 0 33.021 0 30h-15c-8.291 0-15 6.709-15 15v62c0 8.291 6.709 15 15 15h60c8.291 0 15-6.709 15-15v-62c0-8.291-6.709-15-15-15h-15c0-3.021 0-33.021 0-30h90c0 3.021 0 33.021 0 30h-15c-8.291 0-15 6.709-15 15v62c0 8.291 6.709 15 15 15h60c8.291 0 15-6.709 15-15v-62c0-8.291-6.709-15-15-15h-15c0-3.021 0-33.021 0-30h90c0 3.021 0 33.021 0 30h-15c-8.291 0-15 6.709-15 15v62c0 8.291 6.709 15 15 15h60c8.291 0 15-6.709 15-15v-62c0-8.291-6.709-15-15-15h-15v-45c0-8.291-6.709-15-15-15zm-211.802-154.409c5.85-5.807 15.343-5.883 21.211.029 4.307 4.329 10.704 4.75 16.025 2.551s9.566-7.019 9.566-13.171c0-8.276-6.723-15-15-15-24.814 0-45-20.185-45-45 0-19.307 12.361-35.947 30-42.237v-17.763c0-8.291 6.709-15 15-15s15 6.709 15 15v17.726c6.646 2.352 12.299 5.924 16.831 10.472 5.845 5.859 5.845 15.366-.029 21.211s-15.366 5.845-21.211-.029c-4.285-4.321-10.675-4.735-15.998-2.534s-9.578 7.016-9.593 13.154c0 8.276 6.723 15 15 15 24.814 0 45 20.185 45 45 0 19.307-12.361 35.947-30 42.237v17.763c0 8.291-6.709 15-15 15s-15-6.709-15-15v-17.726c-6.646-2.352-12.299-5.924-16.831-10.472-5.845-5.859-5.845-15.366.029-21.211z' />
    </g>
  </SvgIcon>
);

export default Average;
