/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Assay = (props) => (
  <SvgIcon viewBox='0 0 493.9 493.9' {...props}>
    <g id='XMLID_673_'>
      <path id='XMLID_697_' d='m167.73 0v168.667h-13.076v30h13.076v133.508l-56.525 83.89v-217.398h13.517v-30h-123.334v30h13.517v247.084c0 25.145 19.378 45.837 43.983 47.958l-.129.192h433.754v-493.901zm-86.525 198.667v72.667h-36.3v-72.667zm-36.3 247.084v-144.418h36.3v144.417c0 10.008-8.143 18.149-18.15 18.149s-18.15-8.14-18.15-18.148zm184.006-247.084v142.672l28.573 42.405h-88.327l28.573-42.405v-142.672zm-113.764 265.233 33.796-50.157h128.755l33.796 50.157zm347.365 0h-114.844l-88.758-131.726v-133.507h13.076v-30h-74.256v-138.667h264.782z' />
      <path id='XMLID_1450_' d='m309.508 60.247h-81.753v81.753h81.753zm-30 51.753h-21.753v-21.753h21.753z' />
      <path id='XMLID_1451_' d='m316.354 198.667h103.334v30h-103.334z' />
      <path id='XMLID_1452_' d='m316.354 261.95h103.334v30h-103.334z' />
      <path id='XMLID_1453_' d='m316.354 325.234h103.334v30h-103.334z' />
    </g>
  </SvgIcon>
);

export default Assay;
