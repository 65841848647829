import React from 'react';
import PropTypes from 'prop-types';
import {List, ListItem, Typography} from '@mui/material';
import {Link} from 'react-router-dom';

const DetailsTitle = props => {
  return (
    <List disablePadding sx={styles.list}>
      <ListItem sx={{flexDirection: 'column'}}>
        <Typography variant={props.titleVariant} sx={{...styles.basicText, ...props.titleStyle}}>
          {props.title}
        </Typography>
        {props.subtitle && !props.subtitleLink && (
          <Typography variant={props.subtitleVariant} sx={{...styles.basicText, ...props.subtitleStyle}}>
            {props.subtitle}
          </Typography>
        )}
        {props.subtitle && props.subtitleLink && (
          <Link to={props.subtitleLink}>
            <Typography variant='body2' sx={{...styles.basicText, color: '#ed1c24'}}>{props.subtitle}</Typography>
          </Link>
        )}
      </ListItem>
    </List>
  );
};

const styles = {
  list: {
    marginTop: '-16px',
    marginBottom: '16px',
  },
  basicText: {
    lineHeigt: '.9',
    textAlign: 'center',
  },
};
DetailsTitle.defaultProps = {
  titleVariant: 'h6',
  subtitleVariant: 'body2',
  subtitleLink: null,
  subtitle: null,
};
DetailsTitle.propTypes = {
  /** The title of the section */
  title: PropTypes.string.isRequired,
  /** Any one of the typography variants of MaterialUI to be applied to the title */
  titleVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2']),
  /** Any styling that will be applied to the title Typography */
  titleStyle: PropTypes.object,
  /** The sting displayed as a subtitle */
  subtitle: PropTypes.string,
  /** Any styling that will be applied to the subtitle Typography */
  subtitleStyle: PropTypes.object,
  /** Any one of the typography variants of MaterialUI to be applied to the subtitle */
  subtitleVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2']),
  /** If the subtitle is a link, this holds the url */
  subtitleLink: PropTypes.string,
};
export default DetailsTitle;
