import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import {TextField, AutoCompleteValue} from '@kbi/story-forms';
import {usStates, canadaProvinces, mexicanStates} from 'constants';

const DefaultAddressFields = () => {
  return (
    <Fragment>
      <Grid item xs={12} sm={6}>
        <TextField name='Street1' label='Street 1' required disabled={true} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='Street2' label='Street 2' disabled={true} />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField name='CityMunicipality' label='City' required disabled={true} />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField name='StateAdminArea' label='State' required disabled={true} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField name='PostalCode' label='Postal Code' required disabled={true} />
      </Grid>
    </Fragment>
  );
};
const UnitedStatesAddressFields = () => {
  return (
    <Fragment>
      <Grid item xs={12} sm={6}>
        <TextField name='Street1' label='Street 1' required />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='Street2' label='Street 2' />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField name='CityMunicipality' label='City' required />
      </Grid>
      <Grid item xs={12} sm={3}>
        <AutoCompleteValue name='StateAdminArea' label='State' required options={usStates} optionKey='value' />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField name='PostalCode' label='Zip Code' required />
      </Grid>
    </Fragment>
  );
};
const CanadaAddressFields = () => {
  return (
    <Fragment>
      <Grid item xs={12} sm={6}>
        <TextField name='Street1' label='Street 1' required />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='Street2' label='Street 2' />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField name='CityMunicipality' label='City' required />
      </Grid>
      <Grid item xs={12} sm={3}>
        <AutoCompleteValue name='StateAdminArea' label='Province' required options={canadaProvinces} optionKey='value' />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField name='PostalCode' label='Postal Code' required />
      </Grid>
    </Fragment>
  );
};
const IsraelAddressFields = () => {
  return (
    <Fragment>
      <Grid item xs={12} sm={6}>
        <TextField name='Street1' label='Street 1' required />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='Street2' label='Street 2' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='PostalCode' label='Postal Code' required />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='CityMunicipality' label='City' required />
      </Grid>
    </Fragment>// Nahal Snir St 43, Modi'in-Maccabim-Re'ut, Israel
  );
};
const MexicoAddressFields = () => {
  return (
    <Fragment>
      <Grid item xs={12} sm={6}>
        <TextField name='Street1' label='Street 1' required />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='Street2' label='Street 2' />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField name='CityMunicipality' label='City' required />
      </Grid>
      <Grid item xs={12} sm={3}>
        <AutoCompleteValue name='StateAdminArea' label='State' required options={mexicanStates} optionKey='value' />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField name='PostalCode' label='Postal Code' required />
      </Grid>
    </Fragment>
  );
};

const AddressFieldSelector = ({country}) => {
  switch (country) {
  case 'United States':
    return <UnitedStatesAddressFields />;
  case 'Canada':
    return <CanadaAddressFields />;
  case 'Israel':
    return <IsraelAddressFields />;
  case 'Mexico':
    return <MexicoAddressFields />;
  default:
    return <DefaultAddressFields />;
  }
};

AddressFieldSelector.propTypes = {country: PropTypes.string};
export default AddressFieldSelector;
